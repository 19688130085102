
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
// import KTAside from "@/layout/aside/Aside.vue";
// import KTHeader from "@/layout/header/Header.vue";
// import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
// import KTToolbar from "@/layout/toolbar/Toolbar.vue";
// import KTScrollTop from "@/layout/extras/ScrollTop.vue";
// import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
// import KTLoader from "@/components/Loader.vue";
// import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
// import KTExplore from "@/layout/extras/Explore.vue";
// import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import { RouterLink, RouterView } from 'vue-router'

export default defineComponent({
  name: "Layout",
  components: {
    // KTAside,
    // KTHeader,
    // KTFooter,
    // KTToolbar,
    // KTScrollTop,
    // KTCreateApp,
    // KTUserMenu,
    // KTExplore,
    // KTDrawerMessenger,
    // KTLoader,
    // KTHeader
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      console.log(store.getters.pageBreadcrumbPath)
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      // console.log(store.getters.currentUser)

      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    const goToHome = () => {
      router.push({ name: "home" })
    }

    const goToApprove = () => {
      console.log(123)
      router.push({ name: "approve" })
    }

    const goToPriceCheck = () => {
      router.push({ name: "price_check" })
    }

    const goToPcmVersion = () => {
      router.push({ name: "pcm_version" })
    }

    const goToTag = () => {
      router.push({ name: "tag" })
    }

    const goToStore = () => {
      router.push({ name: "store" })
    }

    const goToMember = () => {
      router.push({ name: "member" })
    }

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const current_user = store.getters.currentUser;
    console.log(current_user)

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      route,
      goToHome,
      goToApprove,
      goToTag,
      goToStore,
      goToMember,
      store,
      signOut,
      goToPriceCheck,
      current_user,
      goToPcmVersion
    };
  },
});
